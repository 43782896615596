import i18n from "i18n";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import SuggestionsManager from "../../components/SuggestionsManager";
import { regexMail, regexPhoneNumber } from "../../helpers/utilities";
import cockpitActions from "../../redux/actions/cockpitActions";
import engageActions from "../../redux/actions/engageActions";
import { Clear } from "@material-ui/icons";

/**
 * For now this component is made for ML but it should comply with any channel type
 */
export default function EngageEditContact() {
	const {
		selectedAssistantID,
		user,
		selectedfID,
		substate,
		draftEngageLinkContact,
		selectedConversation
	} = useSelector(state => {
		return {
			selectedAssistantID: state?.selectedAssistantID,
			user: state?.userStatus?.auth?.user,
			selectedfID: state?.cockpit?.selectedConversation?.header?.fID,
			substate: state?.cockpit?.selectedConversation?.header?.substate,
			draftEngageLinkContact: state?.cockpit?.selectedConversation?.drafts?.engageLinkContact,
			selectedConversation: state?.cockpit?.selectedConversation
		};
	});

	const [contact, setContact] = useState(draftEngageLinkContact || "");
	const [emailSuggestions, setEmailSuggestions] = useState([]);
	const [isTyping, setIsTyping] = useState(false);
	const [fullContact, setFullContact] = useState({});
	const isConversationTypeTicket = selectedConversation?.header?.type === "ticket";

	const dispatch = useDispatch();

	const Suggestions = new SuggestionsManager(selectedAssistantID, user, contact);

	/**
	 *
	 */
	useEffect(() => {
		if (isTyping) {
			const timeoutId = setTimeout(async () => {
				if (validateContact()) {
					dispatch(engageActions.setEngageContact(contact.trim()));
					const fID = selectedfID;
					const newDraftValues = {
						engageLinkContact: contact
					};
					let data = {
						header: {
							assistantID: selectedConversation?.header?.assistantID,
							fID: selectedConversation?.header?.fID
						},
						payload: {
							contact: {}
						}
					};

					if (Object.keys(fullContact).length !== 0) {
						// If there is a full contact because user has clicked on
						data.payload.contact = fullContact;
					}
					dispatch(cockpitActions.launchActionFrontV2("updateConvContact", data));

					dispatch(cockpitActions.postDraft(fID, selectedAssistantID, substate, newDraftValues));
				} else {
					dispatch(engageActions.setEngageContact(""));
				}
				const onSucces = suggestions => {
					setEmailSuggestions(
						suggestions.filter(suggestion => {
							const suggestionType = isConversationTypeTicket ? suggestion.email : suggestion.phone;
							return suggestion.type !== "list" && suggestionType && suggestionType !== "";
						})
					);
				};
				if (isConversationTypeTicket) await Suggestions.loadSuggestions(onSucces);
				else await Suggestions.loadSuggestions(onSucces, null, contact);

				setIsTyping(false);
			}, 300);
			return () => clearTimeout(timeoutId);
		}
	}, [contact]);

	/**
	 * When we change the conversation, empty this fields
	 */
	useEffect(() => {
		setContact(draftEngageLinkContact || "");
		dispatch(engageActions.setEngageContact(draftEngageLinkContact || ""));
	}, [selectedfID]);

	/**
	 *
	 * @param {*} evt
	 * @param {*} newValue
	 * @param {*} reason
	 * @returns
	 */
	const handleAutocompleteChange = (evt, newValue, reason) => {
		let newContact;
		if (isConversationTypeTicket) {
			newContact = newValue.email;
		} else {
			newContact = newValue.phone;
			newContact = newContact.replaceAll(" ", "");
		}
		if (!newContact) {
			return;
		} else {
			setContact(newContact);

			setFullContact(newValue);
			setIsTyping(true);
		}
	};

	/**
	 *
	 * @param {*} evt
	 */
	const handleContactInputChange = evt => {
		let newValue = evt.target.value;
		setContact(newValue);
		if (Object.keys(fullContact).length !== 0) {
			setFullContact({});
		}
		setIsTyping(true);
	};

	/**
	 *
	 * @param {*} e
	 */
	const handleDelete = e => {
		setContact("");
		setIsTyping(true);
	};

	/**
	 *
	 * @returns
	 */
	const validateContact = () => {
		if (isConversationTypeTicket) {
			return !regexMail(contact);
		} else {
			return !regexPhoneNumber(contact);
		}
	};

	return (
		<Autocomplete
			inputValue={contact}
			id="autocomplete-recipient"
			freeSolo
			options={emailSuggestions}
			style={{ minWidth: "150px", maxWidth: "300px", width: "100%" }}
			getOptionLabel={option => (isConversationTypeTicket ? option.email : option.phone)}
			multiple={false}
			disableClearable
			onChange={handleAutocompleteChange}
			filterOptions={(options, state) => options} //FilterOptions is made in authDev, no filter needed front side
			renderOption={(suggestedValue, option) =>
				Suggestions.display(suggestedValue, option, isConversationTypeTicket ? false : contact)
			}
			renderInput={params => (
				<Box style={{ position: "relative" }}>
					<TextField
						{...params}
						label={i18n.t("COC.emailTo")}
						variant="outlined"
						size="small"
						onChange={handleContactInputChange}
						error={!validateContact(contact) ? true : false}
					/>

					{/* </Box> */}
					<IconButton
						size="small"
						onClick={e => handleDelete()}
						style={{ position: "absolute", right: "10px", top: "5px" }}
					>
						<Clear />
					</IconButton>

					<Box
						style={{
							position: "absolute",
							top: "100%",
							color: "red"
						}}
					>
						{!validateContact()
							? isConversationTypeTicket
								? i18n.t("TabInfo.invalidEmail")
								: i18n.t("TabInfo.invalidPhone")
							: ""}
					</Box>
				</Box>
			)}
		/>
	);
}

/**
 * For now this component is made for ML but it should comply with any channel type
 */
export function EngageSearchContact({ handleChangeContact }) {
	const { selectedAssistantID, user } = useSelector(state => {
		return {
			selectedAssistantID: state?.selectedAssistantID,
			user: state?.userStatus?.auth?.user
		};
	});

	const [contact, setContact] = useState("");
	const [emailSuggestions, setEmailSuggestions] = useState([]);
	const [isTyping, setIsTyping] = useState(false);
	const [fullContact, setFullContact] = useState({});

	const Suggestions = new SuggestionsManager(selectedAssistantID, user, contact);

	/**
	 *
	 */
	useEffect(() => {
		if (isTyping) {
			const timeoutId = setTimeout(async () => {
				const onSucces = suggestions => {
					setEmailSuggestions(
						suggestions.filter(suggestion => {
							const suggestionType = suggestion.email;
							return suggestion.type !== "list" && suggestionType && suggestionType !== "";
						})
					);
				};
				await Suggestions.loadSuggestions(onSucces);

				setIsTyping(false);
			}, 300);
			return () => clearTimeout(timeoutId);
		}
	}, [contact]);

	/**
	 *
	 * @param {*} evt
	 * @param {*} newValue
	 * @param {*} reason
	 * @returns
	 */
	const handleAutocompleteChange = (evt, newValue, reason) => {
		let newContact;
		newContact = newValue?.email || newValue?.phone;
		newContact = newContact.replaceAll(" ", "");

		if (!newContact) {
			return;
		} else {
			handleChangeContact(newValue);
			setContact(newContact);
			setFullContact(newValue);
			setIsTyping(true);
		}
	};

	/**
	 *
	 * @param {*} evt
	 */
	const handleContactInputChange = evt => {
		let newValue = evt.target.value;
		setContact(newValue);
		handleChangeContact(newValue);

		if (Object.keys(fullContact).length !== 0) {
			setFullContact({});
		}
		setIsTyping(true);
	};

	/**
	 *
	 * @param {*} e
	 */
	const handleDelete = e => {
		setContact("");
		handleChangeContact({});
		setIsTyping(true);
	};

	/**
	 *
	 * @returns
	 */
	const validateContact = () => {
		return !regexMail(contact) || !regexPhoneNumber(contact);
	};

	return (
		<Autocomplete
			inputValue={contact}
			id="autocomplete-recipient"
			freeSolo
			options={emailSuggestions}
			getOptionLabel={option => option.email}
			multiple={false}
			disableClearable
			onChange={handleAutocompleteChange}
			filterOptions={(options, state) => options} //FilterOptions is made in authDev, no filter needed front side
			renderOption={(suggestedValue, option) => Suggestions.display(suggestedValue, option, false)}
			renderInput={params => (
				<Box style={{ position: "relative" }}>
					<TextField
						{...params}
						label="Contact"
						variant="outlined"
						size="small"
						onChange={handleContactInputChange}
						error={!validateContact(contact) ? true : false}
					/>

					{/* </Box> */}
					<IconButton
						size="small"
						onClick={e => handleDelete()}
						style={{ position: "absolute", right: "10px", top: "5px" }}
					>
						<Clear />
					</IconButton>
					<Box
						style={{
							top: "100%",
							color: "red"
						}}
					>
						{!validateContact() ? i18n.t("TabInfo.invalidEngage") : ""}
					</Box>
				</Box>
			)}
		/>
	);
}
