import React from "react";
import copy from "copy-to-clipboard";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default function useCopyToClipboard(resetInterval = null, nationalPhone = false) {
	const [isCopied, setCopied] = React.useState(false);

	const handleCopy = React.useCallback(text => {
		let textToCopy = text;

		// Verif if it's a phone number and convert then to national format
		if (typeof text === "string" && text.startsWith("+") && nationalPhone === true) {
			try {
				const phoneNumber = parsePhoneNumberFromString(text);
				if (phoneNumber && phoneNumber.isValid()) {
					textToCopy = phoneNumber.formatNational();
				}
			} catch (error) {
				textToCopy = text;
			}
		}

		if (typeof textToCopy === "string" || typeof textToCopy === "number") {
			copy(textToCopy.toString());
			setCopied(true);
		} else {
			setCopied(false);
			// console.error(`Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`);
		}
	}, []);

	React.useEffect(() => {
		let timeout;
		if (isCopied && resetInterval) {
			timeout = setTimeout(() => setCopied(false), resetInterval);
		}
		return () => {
			clearTimeout(timeout);
		};
	}, [isCopied, resetInterval]);

	return [isCopied, handleCopy];
}
