/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import {
	TextField,
	IconButton,
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Select,
	InputLabel,
	FormControl,
	MenuItem
} from "@material-ui/core";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import lod_ from "lodash";
import MuiPhoneNumber from "material-ui-phone-number";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";

/**
 * Form specific: The dialog to create or update any object, with the data from conversation and build form with a dico from cluster client
 */
function InputType({ value, config, language, path, customErrors, setCustomErrors, onChange }) {
	useEffect(() => {
		switch (config.type) {
			case "boolean": {
				if (lod_.isNil(value)) {
					onChange(false, path, config);
				}
				break;
			}
			case "string":
			case "email": {
				if ((lod_.isNil(value) || lod_.isEmpty(value)) && config?.isRequired === true) {
					setCustomErrors(prev => {
						return {
							...prev,
							[path]: i18n.t(`COC.actionForm.FORMS.required`)
						};
					});
				}
				break;
			}
			default: {
				if (lod_.isNil(value) && config?.isRequired === true) {
					setCustomErrors(prev => {
						return {
							...prev,
							[path]: i18n.t(`COC.actionForm.FORMS.required`)
						};
					});
				}
				break;
			}
		}
	}, []);

	switch (config.type) {
		case "boolean":
			return (
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={value}
								disabled={config?.actions?.edit === false}
								onChange={e => onChange(e.target.checked, path, config)}
								name="conversations"
							/>
						}
						label={config?.label[language]}
					/>
				</FormGroup>
			);
		case "code":
			return (
				<Box pt={2}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="select-label">{config?.label[language]}</InputLabel>
						<Select
							labelId="select-label"
							id="select"
							label={config?.label[language]}
							disabled={config?.actions?.edit === false}
							value={value}
							onChange={e => onChange(e.target.value, path, config)}
						>
							{config.codes
								.sort((a, b) => a?.label.localeCompare(b?.label))
								.map((code, index) => (
									<MenuItem key={index} value={code.value}>
										{code.label}
									</MenuItem>
								))}
						</Select>
						<p style={{ color: "red" }}>{customErrors}</p>
					</FormControl>
				</Box>
			);
		case "number":
			return (
				<Box pt={2}>
					<TextField
						id="custom-number"
						type="number"
						label={config?.label[language]}
						value={!lod_.isNil(value) ? value : ""}
						onChange={e => onChange(e.target.value, path, config)}
						fullWidth
						disabled={config?.actions?.edit === false}
						InputProps={{
							endAdornment: !lod_.isNil(value) && config?.actions?.edit !== false && (
								<IconButton
									onClick={() => {
										onChange(null, path, config);
									}}
									size="small"
								>
									<Close />
								</IconButton>
							)
						}}
						variant="outlined"
					/>
					<p style={{ color: "red" }}>{customErrors}</p>
				</Box>
			);
		case "string":
		case "email":
			return (
				<Box pt={2}>
					<TextField
						id="custom-text"
						label={config?.label[language]}
						value={value}
						onChange={e => onChange(e.target.value, path, config)}
						fullWidth
						disabled={config?.actions?.edit === false}
						InputProps={{
							endAdornment: !lod_.isEmpty(value) && config?.actions?.edit !== false && (
								<IconButton
									onClick={() => {
										onChange("", path, config);
									}}
									size="small"
								>
									<Close />
								</IconButton>
							)
						}}
						variant="outlined"
					/>
					<p style={{ color: "red" }}>{customErrors}</p>
				</Box>
			);
		case "date":
			return (
				<Box pt={2}>
					<MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
						<KeyboardDatePicker
							fullWidth
							inputVariant="outlined"
							format="dd/MM/yyyy"
							disabled={config?.actions?.edit === false}
							label={config?.label[language]}
							value={!lod_.isNil(value) ? value : null}
							onChange={e => onChange(e, path, config)}
							disableToolbar
							onError={() => null}
							helperText=""
						/>
					</MuiPickersUtilsProvider>
					<p style={{ color: "red" }}>{customErrors}</p>
				</Box>
			);
		case "phone":
			return (
				<Box pt={2}>
					<MuiPhoneNumber
						fullWidth
						variant="outlined"
						id="custom-phone"
						label={config?.label[language]}
						value={!lod_.isNil(value) ? value : ""}
						defaultCountry={"fr"}
						onChange={e => onChange(e, path, config)}
						disabled={config?.actions?.edit === false}
						disableDropdown={config?.actions?.edit === false}
						InputProps={{
							endAdornment: !lod_.isEmpty(value) && config?.actions?.edit !== false && (
								<IconButton
									onClick={() => {
										onChange(null, path, config);
									}}
									size="small"
								>
									<Close />
								</IconButton>
							)
						}}
					/>
					<p style={{ color: "red" }}>{customErrors}</p>
				</Box>
			);
		default:
			return <></>;
	}
}

export default InputType;
