import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "helpers/utilities";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import i18n from "i18n";
import cockpitActions from "redux/actions/cockpitActions";
import {
	ListItemIcon,
	ListItemText,
	Menu,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button
} from "@material-ui/core";
import { IconButton, MenuItem } from "@mui/material";
import AvTimerOutlinedIcon from "@material-ui/icons/AvTimerOutlined";
import { Box } from "@mui/system";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import SendIcon from "@material-ui/icons/Send";
import { regexMail } from "helpers/utilities";
import { regexPhoneNumber } from "helpers/utilities";
import lod_ from "lodash";
import { ModalResolved } from "../ConvActions/ConvItemComponents";
const DropDownMenu = ({ anchorEl, setAnchorEl, emailCCListGlobal, emailBCCListGlobal, answer }) => {
	const dispatch = useDispatch();
	const { selectedConversation, convSending, currentMessage, attachments, topicDraft, urgent } =
		useSelector(state => state.cockpit, shallowEqual);
	const { assistantconfig } = useSelector(state => state);
	const { sendEnabled } = useSelector(state => state.conversation);
	const { limit } = useSelector(state => state.assistantconfig);
	const engageContact = useSelector(state => state?.engage?.contact);
	const [open, setOpen] = useState(false);
	const [actionSend, setActionSend] = useState(false);
	const [CC, setCC] = useState([]);
	const [BCC, setBCC] = useState([]);
	const [state, setState] = useState(null);
	const [substate, setSubstate] = useState(null);
	const [messageDialogAnswer, setMessageDialogAnswer] = useState("");
	const handleClickOpen = () => {
		setOpen(true);
	};

	const [convContact, setConvContact] = React.useState([]);

	const [openModalResolved, setOpenModalResolved] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};
	//const [anchorEl, setAnchorEl] = useState(null);

	const conversationHasNoContact = Boolean(
		!selectedConversation?.contact?.email &&
			!selectedConversation?.contact?.uid &&
			!selectedConversation?.contact?.id &&
			!engageContact
	); // Condition added for new engage
	const emptyMessageTicket =
		(answer === "" && attachments.length === 0) ||
		(answer === "<p><br></p>" && attachments.length === 0);

	let disableSendTicket = conversationHasNoContact || emptyMessageTicket;

	let disableSendConversational =
		convSending ||
		(sendEnabled &&
			answer === "" &&
			!currentMessage?.message?.payload?.subject &&
			!attachments?.length) ||
		(selectedConversation.header.channel === "LC" &&
			!selectedConversation.meta?.cockpit?.connected);
	let noTopic =
		assistantconfig?.cockpit?.topicMandatory &&
		(!selectedConversation.meta.topic ||
			selectedConversation.meta.topic === "nointent" ||
			!assistantconfig.intents.some(obj => obj.name === selectedConversation.meta.topic));

	let disableSend = assistantconfig?.cockpit?.topicMandatory
		? (sendEnabled && answer === "") ||
			!selectedConversation.meta.topic ||
			selectedConversation.meta.topic === "nointent" ||
			!assistantconfig.intents.some(obj => obj.name === selectedConversation.meta.topic)
		: sendEnabled && answer === "";
	const typeConv = selectedConversation?.header.type;

	const handleClickDropDown = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseDropDown = () => {
		setAnchorEl(null);
	};

	/**
	 *
	 * @param {*} emailCCListGlobal
	 * @param {*} emailBCCListGlobal
	 * @param {*} urgent
	 * @param {*} state
	 * @param {*} substate
	 */
	const handleConv = (
		emailCCListGlobal,
		emailBCCListGlobal,
		urgent,
		state,
		substate,
		conversationsAlreadyResolved = null,
		success = null
	) => {
		handleCloseDropDown();
		if (sendEnabled) {
			let OKtoSend = true;
			const totalAttachmentSizeLimit = limit?.outgoingMailMaxSizeInMeg;

			if (totalAttachmentSizeLimit) {
				// Are we over the limit?
				let totalAttachmentSize = 0;
				attachments.forEach(att => {
					totalAttachmentSize += att.size;
				});
				totalAttachmentSize = parseFloat(totalAttachmentSize / (1024 * 1024));

				if (totalAttachmentSize > totalAttachmentSizeLimit) {
					dispatch(
						errorMsg(
							`${i18n.t(
								"EDIT_FORM.error_messages.attachmentsSizeOverLimit"
							)}${totalAttachmentSizeLimit} MB`
						)
					);
					OKtoSend = false;
				}
			}

			if (OKtoSend) {
				let testAnwser = document.createElement("div");
				testAnwser.innerHTML = answer;

				// Extraire la réponse pour tester si elle est vide
				let text = testAnwser.textContent || testAnwser.innerText;
				let anwserNotEmpty = /\S/.test(text);
				let topicNotEmpty = /\S/.test(topicDraft);

				setCC(emailCCListGlobal);
				setBCC(emailBCCListGlobal);
				setState(state);
				setSubstate(substate);

				if (
					engageContact === "" &&
					selectedConversation?.header?.channel === "ML" &&
					selectedConversation?.header?.substate === "draft"
				) {
					setMessageDialogAnswer(i18n.t("COC.noSendSendNoTo"));
					setActionSend(false);

					handleClickOpen();
				} else if (!anwserNotEmpty && !topicNotEmpty && !attachments?.length) {
					setMessageDialogAnswer(i18n.t("COC.noSendSendNoSubject"));
					setActionSend(false);

					handleClickOpen();
				} else if (!anwserNotEmpty) {
					setMessageDialogAnswer(i18n.t("COC.confirmSendNoAnswer"));
					setActionSend(true);

					handleClickOpen();
				} else if (!topicNotEmpty) {
					setMessageDialogAnswer(i18n.t("COC.confirmSendNoSubject"));
					setActionSend(true);
					handleClickOpen();
				} else {
					dispatch(
						cockpitActions.sendAnswer({
							conv: selectedConversation,
							CC: emailCCListGlobal,
							BCC: emailBCCListGlobal,
							targetStates: { state, substate },
							manualTopic: topicDraft,
							urgent,
							answer,
							message: currentMessage,
							conversationsAlreadyResolved,
							success: success
						})
					);
				}
			}
		}
	};

	const sendConv = () => {
		dispatch(
			cockpitActions.sendAnswer({
				conv: selectedConversation,
				CC,
				BCC,
				targetStates: { state, substate },
				manualTopic: topicDraft,
				urgent,
				answer,
				message: currentMessage
			})
		);
		setActionSend(false);
		handleClose();
	};

	const handleNeedConv = res => {
		setConvContact(res?.checkNeed?.conversations);
		setOpenModalResolved(true);
	};

	const sendAlreadyResolved = conversations => {
		if (!lod_.isEmpty(conversations) && !lod_.isNil(conversations)) {
			handleConv(
				emailCCListGlobal,
				emailBCCListGlobal,
				urgent,
				"resolved",
				"resolved",
				conversations
			);
		} else {
			handleConv(emailCCListGlobal, emailBCCListGlobal, urgent, "resolved", "resolved", []);
		}
	};

	return (
		<Box>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleCloseDropDown}
			>
				<MenuItem
					onClick={() =>
						handleConv(emailCCListGlobal, emailBCCListGlobal, urgent, "ongoing", "waiting_client")
					}
				>
					<ListItemIcon>
						<AvTimerOutlinedIcon fontSize="small" mt={1} />
					</ListItemIcon>
					<ListItemText primary={i18n.t("COC.client")} />
				</MenuItem>
				<MenuItem
					onClick={() =>
						handleConv(emailCCListGlobal, emailBCCListGlobal, urgent, "ongoing", "waiting_action")
					}
				>
					<ListItemIcon>
						<BallotOutlinedIcon fontSize="small" mt={1} />
					</ListItemIcon>
					<ListItemText primary={i18n.t("COC.action")} />
				</MenuItem>
				<Tooltip title={noTopic ? i18n.t("COC.noTopic") : ""} placement="left">
					<span>
						<MenuItem
							disabled={disableSend}
							onClick={() =>
								handleConv(
									emailCCListGlobal,
									emailBCCListGlobal,
									urgent,
									"resolved",
									"resolved",
									null,
									res => {
										handleNeedConv(res);
									}
								)
							}
						>
							<ListItemIcon>
								<DoneOutlineOutlinedIcon fontSize="small" mt={1} />
							</ListItemIcon>
							<ListItemText primary={i18n.t("COC.resolved")} />
						</MenuItem>
					</span>
				</Tooltip>
				{openModalResolved === true && (
					<ModalResolved
						openModalResolved={openModalResolved}
						setOpenModalResolved={setOpenModalResolved}
						convContact={convContact}
						action={"send"}
						actionToDo={conversations => sendAlreadyResolved(conversations)}
					/>
				)}
				<MenuItem
					onClick={() =>
						handleConv(emailCCListGlobal, emailBCCListGlobal, urgent, "resolved", "watchlist")
					}
				>
					<ListItemIcon>
						<RemoveRedEyeOutlinedIcon fontSize="small" mt={1} />
					</ListItemIcon>
					<ListItemText primary={i18n.t("COC.watchlist")} />
				</MenuItem>
			</Menu>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{i18n.t("COC.confirmSend")}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{messageDialogAnswer}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						{i18n.t("COC.cancel")}
					</Button>
					{actionSend && (
						<Button onClick={sendConv} color="primary" autoFocus>
							{i18n.t("COC.send")}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export const SendMessageComponent = ({
	answer,
	emailBCCListGlobal,
	emailCCListGlobal,
	loadingSend
}) => {
	const dispatch = useDispatch();
	const { selectedConversation, convSending, currentMessage, attachments } = useSelector(
		state => state.cockpit,
		shallowEqual
	);
	const user = useSelector(state => state.userStatus.auth.user);
	const assignedConv = selectedConversation.agent?.uid === user.email;
	const { sendEnabled } = useSelector(state => state.conversation);
	const engageContact = useSelector(state => state?.engage?.contact);
	const [anchorEl, setAnchorEl] = useState(null);

	const conversationHasNoContact = Boolean(
		!selectedConversation?.contact?.email &&
			!selectedConversation?.contact?.uid &&
			!selectedConversation?.contact?.id &&
			!engageContact
	); // Condition added for new engage
	const emptyMessageTicket =
		(answer === "" && attachments.length === 0) ||
		(answer === "<p><br></p>" && attachments.length === 0);

	let disableSendTicket = conversationHasNoContact || emptyMessageTicket || !assignedConv;

	let disableSendConversational =
		conversationHasNoContact ||
		convSending ||
		(sendEnabled && (answer === "" || !answer) && !attachments?.length) ||
		(selectedConversation.header.channel === "LC" &&
			!selectedConversation.meta?.cockpit?.connected);

	const typeConv = selectedConversation?.header.type;

	const checkPossibilities = disableSendConversational => {
		if (
			currentMessage?.message?.header?.type === "menu" &&
			!lod_.isNil(currentMessage?.message?.payload?.menu?.choices) &&
			!lod_.isEmpty(currentMessage?.message?.payload?.menu?.choices)
		) {
			return false;
		} else if (disableSendConversational) {
			return true;
		} else {
			return false;
		}
	};

	const handleClickDropDown = event => {
		setAnchorEl(event.currentTarget);
	};

	const sendAnswer = () => {
		dispatch(
			cockpitActions.sendAnswer({
				conv: selectedConversation,
				answer,
				message: currentMessage
			})
		);
	};

	return (
		<>
			<Tooltip title={i18n.t("COC.send")}>
				<Box>
					<IconButton
						size="small"
						disabled={
							loadingSend
								? selectedConversation?.header?.channel !== "ML"
									? checkPossibilities(disableSendConversational)
									: false
								: true
						}
						onClick={typeConv === "ticket" ? handleClickDropDown : () => sendAnswer()}
					>
						<SendIcon color={loadingSend ? "action" : "error"} />
					</IconButton>
				</Box>
			</Tooltip>

			<DropDownMenu
				emailCCListGlobal={emailCCListGlobal}
				emailBCCListGlobal={emailBCCListGlobal}
				answer={answer}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
			/>
		</>
	);
};
