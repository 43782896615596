import { wsConnected, wsDisconnected, wsEvent } from "../actions/ws";
import userStatus from "../../constants/userStatus";
import cockpitConstants from "../../constants/cockpit";
import cockpitActions from "../actions/cockpitActions.js";

import C from "../../constants/selectedAssistant.js";
import Push from "push.js";
import i18n from "i18next";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import { warnMsg } from "redux/reducers/snackMsgsReducers";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import store from "redux/store";
import axios from "axios";

const URL_WS = process.env.REACT_APP_AMBACK_WS;
let websocket;
let dispatchDisconnected = false;
let userLogged = false;
let timeoutId;

let user;
let assistantID;

const SOCKET_STATES = { CONNECTING: 0, OPEN: 1, CLOSING: 2, CLOSED: 3 };

function pong() {
	if (websocket) {
		websocket.send("heartbeat");
	}
}

const wsMiddleware =
	({ dispatch, getState }) =>
	next =>
	action => {
		if (!action) {
			return;
		}

		const closeWebSocket = () => {
			traceWSFailedConnection("closeWebSocket");
			if (websocket) {
				websocket.close();
			}
		};

		if (action.type === C.UPDATE_SELECTEDASSISTANT) {
			// traceWSFailedConnection("UPDATE_SELECTEDASSISTANT");
			assistantID = action.payload;
			if (getState().ws?.id) {
				closeWebSocket();
			}
		}

		if (wsDisconnected.match(action)) {
			// traceWSFailedConnection(`action ${JSON.stringify(action)}`);
			next(action);
			return;
		}

		if (action.type === userStatus.ADD_USER_DATA) {
			// traceWSFailedConnection(`action type ${userStatus.ADD_USER_DATA}`);
			user = action.payload?.user;
			userLogged = true;
		}

		if (userLogged && assistantID && !websocket) {
			// traceWSFailedConnection(`new WebSocket`);
			websocket = new WebSocket(
				`${URL_WS}?token=${user.token}&email=${user.email}&assistantID=${assistantID}&appName=${process.env.REACT_APP_NAME}&appVersion=${process.env.REACT_APP_VERSION}`
			);
			Object.assign(websocket, {
				onopen: () => {
					traceWSFailedConnection("onopen");
					dispatchDisconnected = false;
					dispatch(wsConnected());
					timeoutId = setInterval(pong, 40000);
				},
				onclose: () => {
					traceWSFailedConnection("onclose");
					websocket = null;
					if (!dispatchDisconnected) {
						dispatch(wsDisconnected());
						dispatchDisconnected = true;
					}
					if (timeoutId) {
						clearTimeout(timeoutId);
					}
				},
				onerror: error => {
					traceWSFailedConnection(
						`onerror (A ws error occured ${JSON.stringify(error, [
							"message",
							"arguments",
							"type",
							"name"
						])})`
					);
				},
				onmessage: event => {
					const data = JSON.parse(event.data);
					switch (data.type) {
						case "new_conversation":
							dispatch(cockpitActions.newConversationEvent(data) ?? { type: "EMPTY", data });
							if (data.content?.messages?.[0]?.body?.cleaned) {
								sendDesktopNotification("New conversation", data.content.messages[0].body.cleaned);
							} else if (data.content?.messages?.[0]?.payload?.payload?.contentOfMsg) {
								const contentMsg = data.content.messages[0].payload.payload.contentOfMsg;
								switch (contentMsg) {
									case "text": {
										if (data.content.messages[0].payload.payload.text?.plain_text) {
											sendDesktopNotification(
												"New conversation",
												data.content.messages[0].payload.payload.text.plain_text
											);
										} else {
											// if plain_text not found, just send notification as default
											sendDesktopNotification("New conversation", contentMsg);
										}
										break;
									}
									default: {
										sendDesktopNotification("New conversation", contentMsg);
										break;
									}
								}
							}
							break;

						case "new_message":
							dispatch(cockpitActions.newMessageEvent(data) ?? { type: "EMPTY", data });
							if (data.content?.body?.cleaned) {
								sendDesktopNotification("New message", data.content.body.cleaned);
							} else if (data.content?.payload?.payload?.contentOfMsg) {
								const contentMsg = data.content.payload.payload.contentOfMsg;
								switch (contentMsg) {
									case "text": {
										if (data.content.payload.payload.text?.plain_text) {
											sendDesktopNotification(
												"New message",
												data.content.payload.payload.text.plain_text
											);
										} else {
											// if plain_text not found, just send notification as default
											sendDesktopNotification("New message", contentMsg);
										}
										break;
									}
									default: {
										sendDesktopNotification("New message", contentMsg);
										break;
									}
								}
							}
							break;

						case "human_request":
							// This event is for setting the user humanRequest to true, not the conversation
							if (data?.content === true) {
								if (getState().cockpit.selectedConversation?.header?.fID !== data?.fID) {
									dispatch(infoMsg(i18n.t("COC.humanRqst")));
								}
								dispatch({
									type: "ws/set_human_request",
									payload: true
								});
								dispatch({
									type: "notification/newNotification",
									payload: null
								});
							} else {
								// TODO : PIKA -> Shall we set_human_request to false rather than doing nothing ?
							}
							break;

						// Default event back from the actionFront, update the conversation
						case "update_conversation": {
							// List of events that are related to notes
							const NOTES_EVENTS = ["addConvNote", "saveConvNote", "deleteConvNote"];

							switch (true) {
								/**
								 * Case for notes events
								 */
								case NOTES_EVENTS.includes(data.subtype):
									dispatch(
										cockpitActions.updateConversationNotesEvent(data) ?? { type: "EMPTY", data }
									);
									break;
								/**
								 * Default case for update conversation events
								 */
								default:
									dispatch(cockpitActions.updateConversationEvent(data) ?? { type: "EMPTY", data });
									if (data.subtype === "HSHCreateAffair") {
										dispatch(infoMsg("L'affaire a bien été créée"));
									}
									break;
							}

							break;
						}

						// When a message is translated, event come back here
						case "message_translation":
							dispatch(
								cockpitActions.updateConversationWithTranslation(data) ?? { type: "EMPTY", data }
							);
							break;

						case "sendEmptyMessage":
							dispatch(
								errorMsg(
									"Le contenu du message ou du topic est vide, le message n'a pas pu être envoyé avec succès"
								)
							);
							break;

						case "update_message":
							dispatch(cockpitActions.updateMessageStateEvent(data) ?? { type: "EMPTY", data });
							break;

						// Other events
						case "update_escalation":
							dispatch(cockpitActions.updateEscalationEvent(data) ?? { type: "EMPTY", data });
							break;

						case "redirect_conversation":
							dispatch(cockpitActions.redirectConversationEvent(data) ?? { type: "EMPTY", data });
							break;

						case "error_action_nyo":
							// Try to translate first the error code message, if not put
							// generic error message for an action

							dispatch(
								errorMsg(
									`${i18n.t("errorActionNYO.errorDuring")} ${i18n.t(
										`errorActionNYO.actions.${data.error.code || data.error.action}`
									)} ${i18n.t("errorActionNYO.forConverstation")} ${data.fID}`
								)
							);
							break;

						case "error_action_nyo_event":
							// Try to translate first the error code message, if not put
							// generic error message for an action - type event

							dispatch(
								errorMsg(
									`${i18n.t("errorActionNYO.errorDuring")} ${i18n.t(
										`errorActionNYO.actions.${data.error.code || data.error.action}`
									)}`
								)
							);
							break;

						/**
						 * Display message in the cockpit
						 * {
						 * 	data: {
						 * 		severity: "info" | "warning" | "error",
						 * 		message: "message to display"
						 * 	}
						 * }
						 */
						case "display_message":
							switch (data.data.severity) {
								case "warning":
									dispatch(warnMsg(i18n.t(`messagesAction.${data.data.message}`)));
									break;
								case "error":
									dispatch(errorMsg(i18n.t(`messagesAction.${data.data.message}`)));
									break;
								default:
									if (data?.data?.mustExpire === false) {
										dispatch(
											infoMsg({
												message: i18n.t(`messagesAction.${data.data.message}`),
												duration: false
											})
										);
									} else {
										dispatch(infoMsg(i18n.t(`messagesAction.${data.data.message}`)));
									}

									break;
							}
							break;
						case "display_action_deleteConvs":
							switch (data.data.severity) {
								case "error":
									dispatch(errorMsg(i18n.t(`${data.data.message}`)));
									break;
								case "warning":
									dispatch(infoMsg(i18n.t(`${data.data.message}`)));
									break;
								default:
									dispatch(infoMsg(i18n.t(`${data.data.message}`)));
									break;
							}
							dispatch({ type: cockpitConstants.CLEAR_CONVERSATION });
							dispatch(cockpitActions.getConversationsCountByQuery(data.assistantID));

							break;
						case "delete_conversations":
							dispatch([
								{
									type: cockpitConstants.DELETE_CONVERSATIONS,
									payload: data
								}
							]);

							break;
						// Conversation events - NYO front action events
						// TODO: remove this transformation => back should provide the correct action
						case "update_conversation_priority":
							dispatch({
								type: cockpitConstants.UPDATE_CONV_PRIORITY,
								payload: data.content
							});
							break;
						case "update_conversation_marketplace":
							dispatch({
								type: cockpitConstants.UPDATE_CONV_MARKETPLACE,
								payload: data.content
							});
							break;
						case "update_message_intent":
							dispatch({
								type: cockpitConstants.UPDATE_MESSAGE_INTENT,
								payload: data.content
							});
							break;
						case "update_conversation_context":
							dispatch({
								type: cockpitConstants.UPDATE_CONVERSATION_CONTEXT,
								payload: data.content
							});
							break;
						/**
						 * data.content = {
						 * 	conversation: conversation,
						 *  oldState: String,
						 * }
						 */
						case "update_conversation_state":
							dispatch({
								type: cockpitConstants.UPDATE_CONV_STATE,
								payload: {
									...data.content,
									user: store.getState().userStatus?.auth?.user,
									role: store.getState().userStatus?.auth?.user?.role
								}
							});
							break;
						/**
						 * Handle cockpit analytics page
						 */
						case "cockpit_analytics":
							dispatch({
								type: cockpitConstants.COCKPIT_ANALYTICS,
								payload: data.content
							});
							break;

						default:
							if (data && data.type !== "PING") {
								dispatch(data);
							}
							break;
					}
				}
			});
		}

		if (wsEvent.match(action)) {
			if (websocket?.readyState === SOCKET_STATES.OPEN) {
				websocket.send(JSON.stringify(action.payload));
			}
		}

		next(action);
	};

/**
 * Send a notification to client OS if he accepted to receive them
 * @param {string} name the main
 * @param {string} body the body of the message to display
 */
function sendDesktopNotification(name, body) {
	Push.create(name, {
		body,
		timeout: 5000,
		onClick: function () {
			window.focus();
		}
	});
}

async function traceWSFailedConnection(data) {
	try {
		let storeState = store.getState();

		axios(process.env.REACT_APP_AMBACK + "/WSCNX", {
			method: "POST",
			data: {
				agent: storeState?.userStatus?.auth?.user?.email,
				app: process.env.REACT_APP_NAME,
				ver: process.env.REACT_APP_VERSION,
				env: process.env.REACT_APP_ENV,
				data
			}
		});
	} catch (e) {
		// no code here
	}
}

export default wsMiddleware;
