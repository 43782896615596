/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import SettingsIcon from "@material-ui/icons/Settings";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React, { useEffect, useRef, useState } from "react";
import useStyles from "../../CockpitStyleJs/styleFilter.js";
import Tooltip from "@material-ui/core/Tooltip";
import C from "../../../../constants/cockpit.js";
import cockpitActions from "../../../../redux/actions/cockpitActions.js";

import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";
import InboxIcon from "@material-ui/icons/Inbox";
import { ArrowDownwardOutlined, ArrowUpwardOutlined, DraftsOutlined } from "@material-ui/icons";
import lod_ from "lodash";

/**
 *
 */
export default ({ pathDico }) => {
	const dispatch = useDispatch();
	const classes = useStyles();

	const { auth } = useSelector(state => state.userStatus);
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);
	const { dictionary } = useSelector(state => state.assistantconfig);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [hoveredActions, setHoveredActions] = useState(false);
	const [valueContext, setValueContext] = useState("");
	const [openActions, setOpenActions] = useState(false);
	const [dicoCheck, setDicoCheck] = useState({});
	const [whiteList, setWhiteList] = useState([]);
	const [codesMap, setCodesMap] = useState([]);
	const anchorRefActions = useRef(null);

	function handleListKeyDownActions(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenActions(false);
		}
	}

	/**
	 *
	 */
	useEffect(() => {
		if (hoveredActions) {
			const timer = hoveredActions && setTimeout(handleToggleActions, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenActions(false);
		}
	}, [hoveredActions]);

	// CHECK if path of dico context have whitelist
	const checkTargetDico = path => {
		const pathItems = path.replace(/\./g, ".items.");
		let checkW = lod_.get(dictionary, pathItems);

		return checkW;
	};

	// CHECK if path of dico context have whitelist
	const checkWhitelistAndCodes = dictionary => {
		if (!lod_.isNil(dictionary?.codes) && !lod_.isEmpty(dictionary?.codes)) {
			setCodesMap(dictionary.codes);
		} else if (!lod_.isNil(dictionary?.whitelist) && !lod_.isEmpty(dictionary?.whitelist)) {
			setWhiteList(dictionary.whitelist);
		} else {
			setWhiteList([]);
			setCodesMap([]);
		}
	};

	/**
	 *
	 */
	useEffect(() => {
		const onSuccess = res => {
			if (!lod_.isNil(res) && !lod_.isEmpty(res)) {
				setDicoCheck(res);
				checkWhitelistAndCodes(res);
			} else {
				setDicoCheck({});
				setCodesMap([]);
				setWhiteList([]);
			}
		};
		let targetDico = checkTargetDico(pathDico);

		if (!lod_.isNil(targetDico) && !lod_.isNil(targetDico)) {
			setDicoCheck(targetDico);
			checkWhitelistAndCodes(targetDico);
		} else {
			dispatch(
				cockpitActions.getDicoWithPath({ assistantID: selectedAssistantID }, pathDico, onSuccess)
			);
		}
	}, [selectedAssistantID]);

	/**
	 *
	 */
	const handleToggleActions = () => {
		setOpenActions(prevOpen => !prevOpen);
	};

	/**
	 *
	 * @param {*} event
	 * @returns
	 */
	const handleCloseActions = event => {
		if (anchorRefActions.current && anchorRefActions.current.contains(event.target)) {
			return;
		}
		setOpenActions(false);
	};
	/**
	 *
	 * @param {*} valueContext
	 * @returns
	 */
	const handleValueContext = valueContext => {
		setValueContext(valueContext);
		handleSaveFilter(valueContext);
	};

	/**
	 *
	 */
	const handleSaveFilter = valueContext => {
		dispatch({
			type: C.SET_CONV_PATH_CONTEXT_FILTER,
			payload:
				lod_.isNil(valueContext) || lod_.isEmpty(valueContext)
					? {}
					: { path: pathDico, value: valueContext }
		});
	};

	return (
		<>
			{!lod_.isEmpty(whiteList) && (
				<Tooltip title={i18n.t("COC.contextFilterPath")} placement="top">
					<Box
						className={classes.filterButton}
						mt={1}
						onMouseEnter={() => setHoveredActions(true)}
						onMouseLeave={() => setHoveredActions(false)}
					>
						<ToggleButton
							aria-haspopup="menu"
							aria-controls={openActions ? "menu-list-grow-actions" : undefined}
							ref={anchorRefActions}
							value={openActions}
							size="small"
							onClick={() => (valueContext !== "" ? handleValueContext("") : null)}
							style={
								valueContext !== ""
									? { color: "black", backgroundColor: "rgba(0, 0, 0, 0.15)" }
									: {}
							}
						>
							{valueContext !== "" && (
								<Box style={{ position: "relative", display: "flex" }}>{valueContext}</Box>
							)}

							{valueContext === "" && <SettingsIcon fontSize="small" />}
						</ToggleButton>
						<Popper
							open={openActions}
							anchorEl={anchorRefActions.current}
							className={classes.dropDown}
							role={undefined}
							transition
							disablePortal
						>
							{() => (
								<ClickAwayListener onClickAway={handleCloseActions}>
									<MenuList
										mt={0}
										mb={0}
										pt={0}
										autoFocusItem={openActions}
										id="menu-list-grow-actions"
										onKeyDown={handleListKeyDownActions}
									>
										<Paper>
											<ToggleButtonGroup
												orientation="vertical"
												exclusive
												size="small"
												background="#00000000"
												value={valueContext}
											>
												{whiteList.map((value, index) => (
													<ToggleButton
														value={value}
														key={index}
														onClick={() =>
															valueContext === value
																? handleValueContext("")
																: handleValueContext(value)
														}
														style={
															valueContext === value
																? { color: "black", backgroundColor: "rgba(0, 0, 0, 0.15)" }
																: {}
														}
													>
														<Box style={{ position: "relative", display: "flex" }}>{value}</Box>
													</ToggleButton>
												))}
											</ToggleButtonGroup>
										</Paper>
									</MenuList>
								</ClickAwayListener>
							)}
						</Popper>
					</Box>
				</Tooltip>
			)}

			{!lod_.isEmpty(codesMap) && (
				<Tooltip title={i18n.t("COC.contextFilterPath")} placement="top">
					<Box
						className={classes.filterButton}
						mt={1}
						onMouseEnter={() => setHoveredActions(true)}
						onMouseLeave={() => setHoveredActions(false)}
					>
						<ToggleButton
							aria-haspopup="menu"
							aria-controls={openActions ? "menu-list-grow-actions" : undefined}
							ref={anchorRefActions}
							value={openActions}
							size="small"
							onClick={() => (valueContext !== "" ? handleValueContext("") : null)}
							style={
								valueContext !== ""
									? { color: "black", backgroundColor: "rgba(0, 0, 0, 0.15)" }
									: {}
							}
						>
							{valueContext !== "" && (
								<Box style={{ position: "relative", display: "flex" }}>{valueContext}</Box>
							)}

							{valueContext === "" && <SettingsIcon fontSize="small" />}
						</ToggleButton>
						<Popper
							open={openActions}
							anchorEl={anchorRefActions.current}
							className={classes.dropDown}
							role={undefined}
							transition
							disablePortal
						>
							{() => (
								<ClickAwayListener onClickAway={handleCloseActions}>
									<MenuList
										mt={0}
										mb={0}
										pt={0}
										autoFocusItem={openActions}
										id="menu-list-grow-actions"
										onKeyDown={handleListKeyDownActions}
									>
										<Paper>
											<ToggleButtonGroup
												orientation="vertical"
												exclusive
												size="small"
												background="#00000000"
												value={valueContext}
											>
												{codesMap.map((value, index) => (
													<ToggleButton
														value={value?.value}
														key={index}
														onClick={() =>
															valueContext === value?.value
																? handleValueContext("")
																: handleValueContext(value?.value)
														}
														style={
															valueContext === value?.value
																? { color: "black", backgroundColor: "rgba(0, 0, 0, 0.15)" }
																: {}
														}
													>
														<Box style={{ position: "relative", display: "flex" }}>
															{value?.value}
														</Box>
													</ToggleButton>
												))}
											</ToggleButtonGroup>
										</Paper>
									</MenuList>
								</ClickAwayListener>
							)}
						</Popper>
					</Box>
				</Tooltip>
			)}
		</>
	);
};
