/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import {
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	DialogActions,
	Typography,
	Box,
	InputAdornment
} from "@material-ui/core";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { Close, SettingsSharp } from "@material-ui/icons";
import lod_ from "lodash";
import { useSelector } from "react-redux";

/**
 * Cilumine specific: The dialog to close a task with various options
 * and send to crm to update the resource
 */
function CloseTaskCcExpired({ action, actionObject, handleClose, handleSubmit }) {
	const { selectedConversation } = useSelector(state => state.cockpit);

	// Initialize config data
	const [ccExpiredStatusList, setCcExpiredStatusList] = useState([]);

	// Initialize form data
	const [numberOfAttempts, setNumberOfAttempts] = useState(0);
	const [status, setStatus] = useState("");
	const [taskID, setTaskID] = useState(null);

	useEffect(() => {
		// Initialize config data
		if (!lod_.isNil(actionObject?.config?.ccExpiredStatusList)) {
			setCcExpiredStatusList(actionObject.config.ccExpiredStatusList);
			setStatus(actionObject.config.ccExpiredStatusList[0].code);
		}
		if (!lod_.isNil(actionObject?.config?.taskIDPath)) {
			setTaskID(lod_.get(selectedConversation, actionObject.config.taskIDPath));
		}
	}, []);

	/**
	 * Validate the dialog content form
	 * @returns
	 */
	const validateContentForm = () => {
		return !lod_.isNil(numberOfAttempts) && !lod_.isNil(status) && !lod_.isNil(taskID);
	};

	return (
		<div>
			<Dialog
				fullWidth={true}
				onClose={handleClose}
				maxWidth={"sm"}
				open
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					{i18n.t("TabAction.cilumine.CloseTask.outgoingCall")}
				</DialogTitle>
				<DialogContent dividers>
					<Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
						{/* Warn if no task ID */}
						{lod_.isNil(taskID) && (
							<>
								<Typography style={{ color: "red" }}>
									{i18n.t("TabAction.cilumine.CloseTask.warnMissingTaskID")}
								</Typography>
								<br />
							</>
						)}

						{/* Number of attemps */}
						<Box>
							<TextField
								id="custom-text"
								label={i18n.t("TabAction.cilumine.CloseTask.numberOfAttempts")}
								value={numberOfAttempts}
								onChange={e => setNumberOfAttempts(e.target.valueAsNumber)}
								fullWidth
								variant="outlined"
								type="number"
								error={lod_.isNil(numberOfAttempts)}
							/>
						</Box>

						{/* Status */}
						<Box pt={2}>
							<FormControl fullWidth variant="outlined">
								<InputLabel
									id="demo-simple-select-label"
									style={{ color: lod_.isEmpty(status) ? "red" : null }}
								>
									{i18n.t("TabAction.cilumine.CloseTask.status")}
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={status}
									label={i18n.t("TabAction.cilumine.CloseTask.status")}
									error={lod_.isNil(status)}
									onChange={e => setStatus(e.target.value)}
								>
									{ccExpiredStatusList.map((item, index) => {
										return (
											<MenuItem key={index} value={item.code}>
												{item.label}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} style={{ margin: "2%" }} color="secondary">
						{i18n.t("CD.cancel")}
					</Button>
					<Button
						onClick={() =>
							handleSubmit(
								{
									numberOfAttempts,
									status,
									taskID
								},
								action
							)
						}
						style={{ margin: "2%" }}
						color="primary"
						disabled={!validateContentForm()}
					>
						{i18n.t("CD.validate")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default CloseTaskCcExpired;
