/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import Box from "@material-ui/core/Box";
import useStyles from "../../CockpitStyleJs/styleFilter";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useState } from "react";
import AvTimerOutlinedIcon from "@material-ui/icons/AvTimerOutlined";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import _lod from "lodash";
import generalConstant from "../../../../constants/general";
import {
	Badge,
	Checkbox,
	Chip,
	Divider,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Snackbar,
	TextField,
	Typography
} from "@material-ui/core";
import { BlockOutlined, PersonAddDisabled } from "@material-ui/icons";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import { computeAgentName } from "helpers/conversationUtils";
import { getConversationTypeByLeftTab } from "helpers/utilities";
import { api } from "redux/actions/api";
import GetChannelImage from "helpers/GetChannelImage";
import { realNowFromTS } from "helpers/utilities";
import { Alert } from "@material-ui/lab";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";

import { AssignationAction } from "./AssignationAction";
import { handleSpecialDisplayDate } from "helpers/utilities";
import { formatDate } from "helpers/utilities";
import { getLabelOf } from "helpers/utilities";

/**
 *
 * @returns
 */
export function ListConvsAction({ openDialog, setOpenDialog }) {
	const { users } = useSelector(state => state.userGroup);
	const {
		convSearchFilter,
		convChannelFilter,
		convLanguageFilter,
		convPriorityFilter,
		convSentimentFilter,
		convEscalationFilter,
		convSubstateFilter,
		convConnectedFilter,
		convIntentFilter,
		convAscFilter,
		convStartDateFilter,
		convEndDateFilter,
		convGroupFilter,
		convUserFilter,
		convAgentFilter,
		convSupervisorFilter
	} = useSelector(state => state.cockpit);
	const leftTab = useSelector(state => state.cockpit.leftTab);

	const userEmail = useSelector(state => state.userStatus?.auth?.user?.email);
	const user = useSelector(state => state.userStatus?.auth?.user);
	const userRole = useSelector(state => state.userStatus?.auth?.user?.role);
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);
	const agent = useSelector(state => state.selectedAssistantID);
	const dispatch = useDispatch();
	const [selected, setSelected] = useState([]);

	const classes = useStyles();
	const assistantConfig = useSelector(state => state.assistantconfig);

	const [convs, setConvs] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [messageSnack, setMessageSnack] = useState("");

	const [openSnack, setOpenSnack] = useState(false);
	const [openDialogAssign, setOpenDialogAssign] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const { channels } = useSelector(state => state.assistantconfig);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleChangeState = (state, substate, blacklist = false) => {
		let convSelected = selectedItems.map(i => convs[i]);
		changeStateAction(convSelected, { state, substate, blacklist });
		setAnchorEl(null);
	};

	const handleCloseState = () => {
		setAnchorEl(null);
	};

	/**
	 *
	 * @param {*} convsSelected all conv to do action
	 */
	const assignAction = convsSelected => {
		if (_lod.isEmpty(selected)) {
			setOpenSnack(true);
			setMessageSnack("Veuillez selectionner un agent");
			return;
		}

		let userSelected = selected[0];
		let to = {
			name: computeAgentName(userSelected),
			first: userSelected.name,
			last: userSelected.surname,
			email: userSelected._id
		};

		let data = {
			action: "assignManyConv",
			data: {
				header: {
					assistantID: selectedAssistantID
				},
				payload: {
					to,
					convs: convsSelected
				}
			},
			userGroups: user.groups,
			source: generalConstant.APP_NAME,
			ts: Date.now()
		};

		const onSuccess = () => {
			setOpenDialog(false);
			setSelected([]);
		};

		dispatch(
			api({
				method: "POST",
				type: "cockpit/actionFrontCustom",
				url: `/api/v1/actionFront/custom`,
				data,
				onSuccess
			})
		);
	};

	/**
	 *
	 * @param {*} convsSelected all conv to do action
	 */
	const changeStateAction = (convsSelected, selectedState) => {
		if (_lod.isEmpty(selectedState)) {
			setOpenSnack(true);
			setMessageSnack("Veuillez selectionner un statut");
			return;
		}

		let stateSelected = selectedState;

		let data = {
			action: "changeManySate",
			data: {
				header: {
					assistantID: selectedAssistantID
				},
				payload: {
					state: stateSelected,
					convs: convsSelected,
					topicMandatory: assistantConfig?.cockpit?.topicMandatory
				}
			},
			userGroups: user.groups,
			source: generalConstant.APP_NAME,
			ts: Date.now()
		};

		const onSuccess = () => {
			setOpenDialog(false);
		};

		dispatch(
			api({
				method: "POST",
				type: "cockpit/actionFrontCustom",
				url: `/api/v1/actionFront/custom`,
				data,
				onSuccess
			})
		);
	};

	const rejectAction = convsSelected => {
		let data = {
			action: "rejectManyConv",
			data: {
				header: {
					assistantID: selectedAssistantID
				},
				payload: {
					convs: convsSelected
				}
			},
			userGroups: user.groups,
			source: generalConstant.APP_NAME,
			ts: Date.now()
		};

		const onSuccess = () => {
			setOpenDialog(false);
		};

		dispatch(
			api({
				method: "POST",
				type: "cockpit/actionFrontCustom",
				url: `/api/v1/actionFront/custom`,
				data,
				onSuccess
			})
		);
	};

	const handleClose = () => {
		setOpenSnack(false);
	};

	const closeDialog = () => {
		setOpenDialog(false);
		setSelected([]);
		setSelectedItems([]);
		setConvs([]);
		setOpenSnack(false);
	};

	useEffect(() => {
		if (openDialog) {
			let nbrAssignMany = assistantConfig?.cockpit?.nbrAssignMany || 20;

			const tab = getConversationTypeByLeftTab(leftTab, false);
			const filters = {
				search: convSearchFilter,
				channel: convChannelFilter?.code?.toUpperCase(),
				language: convLanguageFilter,
				priority: convPriorityFilter,
				sentiment: convSentimentFilter,
				escalation: convEscalationFilter,
				subState: leftTab !== 0 && convSubstateFilter,
				connected: leftTab === 3 ? convConnectedFilter : null, // do not send connected if leftab not Live (tab===3)
				intent: convIntentFilter,
				asc: typeof convAscFilter !== "boolean" ? true : convAscFilter,
				startDate: convStartDateFilter,
				endDate: convEndDateFilter,
				groups: convGroupFilter.map(group => group._id),
				users: leftTab === 0 ? [] : convUserFilter,
				// do not send agent for waiting (==0) nor for Live (==3)
				agent:
					!convAgentFilter && leftTab !== 0 && leftTab !== 3 && userRole === "agent"
						? userEmail || ""
						: null,
				supervisor:
					convSupervisorFilter &&
					leftTab !== 0 &&
					leftTab !== 3 &&
					(userRole === "supervisor" || userRole === "soloUser")
						? userEmail
						: null
			};

			let sort = {
				// "header.humanRequest": -1,
				"meta.cockpit.ts": filters.asc ? -1 : 1,
				"header.start": filters.asc ? -1 : 1
			};

			let role = user.role;
			let groups = user.groups;

			const onSuccess = res => {
				if (res?.conversations && !_lod.isEmpty(res?.conversations)) {
					setConvs(res.conversations);

					let select = [];
					res.conversations.map((value, index) => {
						select.push(index);
					});

					setSelectedItems(select);
				} else {
					setConvs([]);
				}
			};

			dispatch(
				api({
					method: "POST",
					type: "cockpit/actionFrontCustom",
					url: `/api/v1/conversations/${selectedAssistantID}/${tab}`,
					data: { groups, role, sort, limit: nbrAssignMany, skip: 0, filters, count: null },
					onSuccess
				})
			);
		}
	}, [openDialog]);

	const assignAllItems = () => {
		let convSelected = selectedItems.map(i => convs[i]);
		assignAction(convSelected);
	};

	const unAssignAllItems = () => {
		let convSelected = selectedItems.map(i => convs[i]);
		rejectAction(convSelected);
	};

	const assignOpenChoice = () => {
		setOpenDialogAssign(true);
	};

	const handleToggleItem = index => {
		setSelectedItems(prevSelected => {
			if (prevSelected.includes(index)) {
				return prevSelected.filter(selectedIndex => selectedIndex !== index);
			} else {
				return [...prevSelected, index];
			}
		});
	};

	const displayTitleConv = conv => {
		if (user?.cockpitSettings?.defaultTopicOrSubject) {
			let title = user.cockpitSettings.defaultTopicOrSubject;
			let lastSubject = conv?.subject;

			if (title === "subject") {
				return lastSubject;
			} else if (title === "topic") {
				return getLabelOf(conv.meta?.topic);
			} else {
				return getLabelOf(conv.meta?.cockpit.title);
			}
		}

		if (conv.meta?.cockpit?.title) {
			return getLabelOf(conv.meta?.cockpit.title);
		}
	};

	return (
		<Box>
			<Dialog
				fullWidth
				maxWidth="md"
				open={openDialog}
				onClose={() => closeDialog()}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{i18n.t("COC.manyActionTicket")}</DialogTitle>
				<DialogContent dividers>
					<Badge badgeContent={selectedItems.length} color="primary">
						<Typography style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1%" }}>
							{i18n.t("modal.choiceConv")}
						</Typography>
					</Badge>
					{_lod.isEmpty(convs) ? (
						<Box display="flex" alignItems="center" width={1} justifyContent="center">
							<Typography>{i18n.t("COC.noElementConv")}</Typography>
						</Box>
					) : (
						<Box>
							<List>
								{convs.map((item, index) => (
									<>
										<Box style={{ display: "flex" }}>
											<ListItem key={index} button onClick={() => handleToggleItem(index)}>
												<Checkbox checked={selectedItems.includes(index)} color="primary" />

												<ListItemAvatar>
													<img
														src={GetChannelImage(
															channels,
															item.meta?.cockpit?.channelCode,
															item?.header?.channel?.toUpperCase()
														)}
														alt="Channel-logo"
														width="30"
														height="30"
														className="logo-channel"
													/>
												</ListItemAvatar>
												<ListItemText
													primary={
														displayTitleConv(item) + " " + `(${item?.meta?.cockpit?.contact})`
													}
													secondary={
														(assistantConfig?.cockpit?.datetime_format?.format
															? handleSpecialDisplayDate(
																	item?.meta?.cockpit?.ts,
																	assistantConfig?.cockpit?.datetime_format?.format,
																	user?.language?.toLowerCase() || "fr"
																)
															: formatDate(item?.meta?.cockpit?.datetime)) +
														" | " +
														item?.header?.fID
													}
												/>
											</ListItem>
										</Box>
									</>
								))}
							</List>
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => closeDialog()} color="secondary">
						{`${i18n.t("COC.Cancel")}`}
					</Button>

					{selectedItems.length !== 0 && (
						<>
							<div>
								<Tooltip title={i18n.t("COC.changeStateAll")}>
									<IconButton onClick={handleClick}>
										<ArchiveOutlinedIcon />
									</IconButton>
								</Tooltip>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									keepMounted
									variant="menu"
									open={Boolean(anchorEl)}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center"
									}}
									transformOrigin={{
										vertical: "bottom",
										horizontal: "center"
									}}
									onClose={handleCloseState}
								>
									<MenuItem onClick={() => handleChangeState("ongoing", "waiting_client")}>
										<ListItemIcon>
											<AvTimerOutlinedIcon fontSize="small" mt={1} />
										</ListItemIcon>
										<ListItemText primary={i18n.t("COC.client")} />
									</MenuItem>
									<MenuItem onClick={() => handleChangeState("ongoing", "waiting_action")}>
										<ListItemIcon>
											<BallotOutlinedIcon fontSize="small" mt={1} />
										</ListItemIcon>
										<ListItemText primary={i18n.t("COC.action")} />
									</MenuItem>
									<MenuItem onClick={() => handleChangeState("resolved", "resolved")}>
										<ListItemIcon>
											<DoneOutlineOutlinedIcon fontSize="small" mt={1} />
										</ListItemIcon>
										<ListItemText primary={i18n.t("COC.resolved")} />
									</MenuItem>
									<MenuItem onClick={() => handleChangeState("resolved", "watchlist")}>
										<ListItemIcon>
											<RemoveRedEyeOutlinedIcon fontSize="small" mt={1} />
										</ListItemIcon>
										<ListItemText primary={i18n.t("COC.watchlist")} />
									</MenuItem>

									<Divider />

									<MenuItem onClick={() => handleChangeState("hidden", "spam")}>
										<ListItemIcon>
											<BlockOutlined fontSize="small" mt={1} />
										</ListItemIcon>
										<ListItemText primary={i18n.t("COC.spam")} />
									</MenuItem>
									<MenuItem onClick={() => handleChangeState("hidden", "spam", true)}>
										<ListItemIcon>
											<PersonAddDisabled fontSize="small" mt={1} />
										</ListItemIcon>
										<ListItemText primary={i18n.t("COC.blacklist")} />
									</MenuItem>
								</Menu>
							</div>

							{leftTab !== 0 && <RejectConvAction unAssignAllItems={unAssignAllItems} />}

							<Box>
								<Tooltip title={i18n.t("COC.assignAll")}>
									<IconButton onClick={assignOpenChoice}>
										<ArrowForwardIcon />
									</IconButton>
								</Tooltip>
							</Box>
						</>
					)}
				</DialogActions>
				<Snackbar
					anchorOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					open={openSnack}
					onClose={handleClose}
					autoHideDuration={5000}
				>
					<Alert severity="warning">{messageSnack}</Alert>
				</Snackbar>
			</Dialog>

			<AssignationAction
				openDialogAssign={openDialogAssign}
				setSelected={setSelected}
				assignAllItems={assignAllItems}
				selected={selected}
				setOpenDialogAssign={setOpenDialogAssign}
			/>
		</Box>
	);
}

function RejectConvAction({ unAssignAllItems }) {
	let canRejectConversation = useSelector(
		state => state?.assistantconfig?.cockpit?.agentCanRejectConversation
	);

	let agentCanAssignationConv = useSelector(
		state => state?.assistantconfig?.cockpit?.agentCanAssignationConv
	);

	const userRole = useSelector(state => state.userStatus?.auth?.user?.role);

	const frontUserCanSwitch = (
		<Tooltip title={i18n.t("COC.CancelCoverAllTicket")} placement="top">
			<IconButton size="small" onClick={() => unAssignAllItems()}>
				<ArrowBackIcon />
			</IconButton>
		</Tooltip>
	);

	if (typeof canRejectConversation !== "boolean") {
		canRejectConversation = false;
	}

	if (canRejectConversation && (userRole !== "agent" || agentCanAssignationConv === true)) {
		return frontUserCanSwitch;
	} else if (!canRejectConversation) {
		return frontUserCanSwitch;
	} else {
		return <div />;
	}
}
