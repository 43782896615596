/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import ImageSearchOutlinedIcon from "@material-ui/icons/ImageSearchOutlined";
import { getACleanURI } from "./utilities";
import AttachmentPreview from "./AttachmentPreview";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		marginBottom: "10px",
		marginTop: "10px",
		borderRadius: "1%"
	},
	heading: {
		fontSize: "0.9em",
		fontWeight: "500",
		flexBasis: "33.33%",
		flexShrink: 0,
		paddingTop: 10
	},
	secondaryHeading: {
		fontSize: "0.9em",
		fontWeight: "500",
		color: "black",
		paddingTop: 10
	},
	AccordionDetails: {
		padding: "0px"
	},
	customAccordionSummary: {
		paddingLeft: "16px",
		paddingRight: "0px",
		width: "90%"
	},

	addAnswer: {
		display: "flex",
		alignItems: "center"
	}
}));

export default props => {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);
	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Accordion
			className={classes.root}
			expanded={expanded === "panel1"}
			onChange={handleChange("panel1")}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				classes={{ root: classes.customAccordionSummary }}
				aria-controls="panel1bh-content"
				id="panel1bh-header"
				display="flex"
				flexDirection="row"
				alignItems="center"
			>
				<Typography className={classes.heading}>{props.heading}</Typography>
				<Typography className={classes.secondaryHeading}>{props.title}</Typography>
				{/* Action Icons */}
				<Box display="flex" flexDirection="row" alignItems="center">
					{props.type === "receive" && props.children.length > 0 && (
						<Box display="flex" flexDirection="row" alignItems="center">
							{/* DownloadIcon */}
							<Box>
								<Tooltip
									title={props.downloadAll}
									placement="top"
									onClick={!props.inProgress ? props.handleFetchReceiveAllAtt : undefined}
								>
									<ToggleButton
										style={{
											paddingLeft: "16px",
											paddingRight: "8px",
											color: "rgba(0, 0, 0, 0.54)",
											borderColor: "transparent",
											backgroundColor: "transparent"
										}}
										size="small"
									>
										{!props.inProgress ? (
											<SystemUpdateAltOutlinedIcon size="small" />
										) : (
											<CircularProgress size="14px" />
										)}
									</ToggleButton>
								</Tooltip>
							</Box>
							{/* Show Inline Filter Icon */}
							{props.showSmallInlineFilterButton === true && (
								<Box>
									<ToggleButtonGroup size="small">
										<Tooltip
											title={
												props.inlineFilter === true ? props.showSmallInline : props.hideSmallInline
											}
											placement="top"
											onClick={props.activeInlineFilter}
										>
											<ToggleButton
												exclusive
												onClick={props.activeInlineFilter}
												fontSize="small"
												value={true}
												style={{
													paddingLeft: "8px",
													paddingRight: "16px",
													color: "rgba(0, 0, 0, 0.54)",
													borderColor: "transparent",
													backgroundColor: "transparent"
												}}
											>
												{props.inlineFilter === true ? (
													<ImageSearchOutlinedIcon />
												) : (
													<CropOriginalIcon />
												)}
											</ToggleButton>
										</Tooltip>
									</ToggleButtonGroup>
								</Box>
							)}
						</Box>
					)}
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Box maxWidth="100%" width="100%" p={1}>
					{props.children.length > 0 ? (
						props.children.map((child, index) => {
							return (
								<AttachmentPreview
									key={`attachment-${index}`}
									attachement={child}
									url={getACleanURI(child.url)}
								/>
							);
						})
					) : (
						<div>{props.emptyMsg}</div>
					)}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};
