import React, { useState, useEffect } from "react";
import i18n from "i18n";

import { useDispatch, useSelector } from "react-redux";
import { Chip, IconButton, Popover, Tooltip } from "@material-ui/core";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { getACleanURI } from "helpers/utilities";
import axios from "axios";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import lod_ from "lodash";

function HeaderConvItemRecording() {
	const [anchorEl, setAnchorEl] = useState(null);
	const [openRecording, setOpenRecording] = useState(false);
	const [urlRecording, setUrlRecording] = useState("");
	const [mimeTypeRecording, setMimeTypeRecording] = useState("");
	const [channelRecordingActive, setChannelRecordingActive] = useState(false);
	const [display, setDisplay] = useState(false);

	let { selectedConversation } = useSelector(state => state.cockpit);
	let { channels } = useSelector(state => state.assistantconfig);
	let { user } = useSelector(state => state.userStatus.auth);

	const dispatch = useDispatch();

	// Initial component setup
	useEffect(() => {
		const channelCode = selectedConversation?.header?.extra?.channelCode;
		if (!lod_.isNil(channelCode) && channelCode !== "") {
			const channelConfig = channels.find(chan => chan.code === channelCode);
			const activeRecording = Boolean(channelConfig?.config?.activeRecording);

			if (activeRecording) {
				setChannelRecordingActive(true);
				setDisplay(true);
			} else {
				// No active recording means there is nothing to fetch extra, so look in db
				const recordingUrl = selectedConversation?.meta?.recording?.file?.url;

				// If we have the recording url use it
				if (!lod_.isNil(recordingUrl) && recordingUrl !== "") {
					setUrlRecording(getACleanURI(recordingUrl));
					setMimeTypeRecording(selectedConversation?.meta?.recording?.file?.mimeType);
					setDisplay(true);
				} else {
					setUrlRecording("");
					setDisplay(false);
				}
			}
		} else {
			setUrlRecording("");
			setDisplay(false);
		}
	}, [selectedConversation?.header?.fID]);

	const handleClick = async event => {
		setAnchorEl(event.currentTarget);
		setOpenRecording(true);
		if (channelRecordingActive) {
			const url = await getRecordingUrl();
			if (url) {
				setUrlRecording(url);
			} else {
				handleCloseRecording();
			}
		}
	};

	const handleCloseRecording = () => {
		setOpenRecording(false);
	};

	/**
	 * Get the recording url from the API
	 * @param {string} assistantID the assistant id of the conversation
	 * @param {string} channelCode the channel code of the conversation
	 * @param {string} conversationID the conversation id of the conversation on the external source
	 * @returns {string} the recording url
	 */
	const getRecordingUrl = async () => {
		const assistantID = selectedConversation?.header?.assistantID;
		const channelCode = selectedConversation?.header?.extra?.channelCode;
		const conversationID = selectedConversation?.header?.extra?.externalInfos?.fID;

		if (!assistantID || !channelCode || !conversationID) {
			dispatch(errorMsg(i18n.t("COC.recordingError.missingInformation")));
			return "";
		}
		let callResult;

		try {
			callResult = await axios({
				method: "GET",
				url: `${process.env.REACT_APP_AMBACK}/api/v1/getRecordingUrl/assistantID/${assistantID}/channel/${channelCode}/conversation/${conversationID}`,
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				}
			});
		} catch (error) {
			dispatch(errorMsg(i18n.t("COC.recordingError.callErrorFatal")));
			return "";
		}

		if (callResult?.data?.result) {
			return callResult.data.result;
		}

		dispatch(errorMsg(i18n.t("COC.recordingError.callError")));
		return "";
	};

	return (
		<>
			{display && (
				<>
					<Tooltip title={i18n.t("COC.listenRecording")} placement="right-start">
						<IconButton size="small" onClick={handleClick}>
							<PlayCircleFilledIcon fontSize="small" />
						</IconButton>
					</Tooltip>
					<Popover
						anchorEl={anchorEl}
						open={openRecording}
						onClose={handleCloseRecording}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center"
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center"
						}}
					>
						{urlRecording && (
							<audio controls controlsList="nodownload">
								<source src={urlRecording} type={mimeTypeRecording} />
							</audio>
						)}
					</Popover>
				</>
			)}

			{selectedConversation?.meta?.call?.status === "done" &&
				!lod_.isNil(selectedConversation?.meta?.call?.missedCallReason) && (
					<Chip
						style={{ backgroundColor: "orange" }}
						label={i18n.t(
							`COC.headerConv.phone.type.${selectedConversation?.meta?.call?.missedCallReason}`
						)}
					/>
				)}
		</>
	);
}

export default HeaderConvItemRecording;
